<template>
  <form-consulta-cadastro
    :formCadastro="formCadastro"
    :controller="controller"
  />
</template>

<script>
import { ConsultaAtendimento } from '@/proxy-api/src/services/classes/Atendimento/ConsultaAtendimento';

import FormConsultaCadastro from '@/components/form/FormConsultaCadastro';
import FormAtendimento from '@/views/atendimento/form/FormAtendimento';

export default {
  components: { FormConsultaCadastro },

  data: function () {
    return {
      controller: new ConsultaAtendimento(),
      formCadastro: FormAtendimento,
    };
  },
};
</script>
